import React, { ReactNode } from 'react'
import { ColorToken, Text } from '@mindfulchefuk/design-system'

const MainCopy = ({
  children,
  id = null,
}: {
  children: ReactNode
  id?: string
}) => (
  <Text
    as="h2"
    variant={{ base: 'body-sm', md: 'body-md' }}
    mx="auto"
    textAlign="center"
    color="pepper"
    id={id}
  >
    {children}
  </Text>
)

const CopyHighlight = ({
  children,
  color = 'pepper',
  id = null,
}: {
  children: ReactNode
  color?: ColorToken
  id?: string
}) => (
  <Text
    color={color}
    as="span"
    variant={{ base: 'body-sm-500', md: 'body-md-500' }}
    id={id ? `${id}-highlight` : null}
  >
    {children}
  </Text>
)

export const partnerships = [
  {
    id: 'kings-award-for-enterprise',
    name: "King's Award for Enterprise",
    image: 'homepage/partners/kings-award-for-enterprise-2024.png',
    width: 56,
    copy: (
      <MainCopy id="homepage-partnership-kings-award">
        The King’s Awards for Enterprise 2024{' '}
        <CopyHighlight id="homepage-partnership-kings-award">
          Sustainable Development Winner
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'b-corp',
    name: 'Certified B-Corp',
    image: 'homepage11-2022/partnerships/b-corp.png',
    width: 34,
    copy: (
      <MainCopy id="homepage-partnership-b-corp-copy">
        Continually using our business as a force for good
      </MainCopy>
    ),
  },
  {
    id: 'one-feeds-two',
    name: 'One Feeds Two',
    image: 'homepage11-2022/partnerships/one-feeds-two.png',
    width: 107,
    copy: (
      <MainCopy id="homepage-partnership-one-feeds-two-copy">
        <CopyHighlight id="homepage-partnership-one-feeds-two">
          Buy a recipe box,{' '}
        </CopyHighlight>
        give a meal to children in need
      </MainCopy>
    ),
  },
  {
    id: 'vitality',
    name: 'Vitality',
    image: 'homepage11-2022/partnerships/vitality.png',
    width: 96,
    copy: (
      <MainCopy id="homepage-partnership-vitality-copy">
        Rewarding{' '}
        <CopyHighlight id="homepage-partnership-vitality-copy">
          healthy lifestyles
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'regenerative-farming',
    name: 'Regenerative Farming',
    image: 'homepage11-2022/partnerships/regenerative-farming.png',
    width: 58,
    copy: (
      <MainCopy id="homepage-partnership-regenerative-farming-copy">
        Supporting British farmers on{' '}
        <CopyHighlight id="homepage-partnership-regenerative-farming">
          regenerative farming practices
        </CopyHighlight>
      </MainCopy>
    ),
  },
  {
    id: 'carbon-neutral',
    name: 'Carbon neutral',
    image: 'homepage11-2022/partnerships/carbon-neutral.png',
    width: 66,
    copy: (
      <MainCopy id="homepage-partnership-carbon-neutral-copy">
        Committed to{' '}
        <CopyHighlight id="homepage-partnership-carbon-neutral">
          Net Zero 2030
        </CopyHighlight>
      </MainCopy>
    ),
  },
]
